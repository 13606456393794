import axios from "axios";
import React, { useEffect, useReducer, useState } from "react";
import Reducer from "../utilities/Reducer";
import { useNavigate, useParams } from "react-router-dom";

import Footer from "./components/Footer";
import Loading from "./components/Loading";

import "../css/style.css";

const Home = () => {
    const [orderId, setOrderId] = useState("");
    const [remarks, setRemarks] = useState({});
    const [eventInfo, setEventInfo] = useState({});
    const [ticketTypes, setTicketTypes] = useState([]);
    const [ticketTypeInfos, setTicketTypeInfos] = useState([]);
    const [ticketTypeNumberOfAvailability, setTicketTypeNumberOfAvailability] = useState([]);
    const [selectedTicketTypes] = useState([]);
    const [promoCode, setPromoCode] = useState("");
    const [promoError, setPromoError] = useState("");
    const [isNotValid, setIsNotValid] = useState(true);
    const [loading, setLoading] = useState(true);
    const [token, setToken] = useState(sessionStorage.getItem("token"));
    const [unlockTicketTypes, setUnlockTicketTypes] = useState([]);
    const [customCheckboxText, setCustomCheckboxText] = useState(false);

    // react hook for navigation
    let navigate = useNavigate();

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    const { eventId } = useParams();

    // fetching resources
    const [resources, setResources] = useState({});

    const { language } = useParams();

    const [languageInfo, dispatch] = useReducer(Reducer, {
        id: 0,
        language: language
    });

    useEffect(() => {
        loadToken();
    }, []); //only on first page load

    useEffect(() => {
        requestResources();
    }, [language]); //everytime language is changed

    const loadToken = () => {
        axios.get("form/token").then((res) => {
            axios.defaults.headers.common["Authorization"] = "Bearer " + res.data;

            setToken(res.data);
            sessionStorage.setItem("token", res.data);

            requestFormSettings();
        });
    };

    const requestFormSettings = () => {
        axios.get(`form/formsettings`).then((res) => {
            startOrder(res.data.posId);
        });
    };


    useEffect(() => {

        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        if (orderId !== "") {
            //setLoading(true);

            async function loadEvent() {
                var event = await requestEvent();

                if (event == null) return;

                sessionStorage.setItem("FUWEvent", JSON.stringify(event));

                if (event.remarks) {
                    setRemarks(JSON.parse(event.remarks))
                }

                var eventInfos = await requestEventInfo(event.id);

                // instead of setting it as an object, we were adding the object to an array!
                setEventInfo(eventInfos.eventInfos.find((infos) => infos.languageId === languageInfo.id));

                var ticketTypesOfEvent = await requestTicketTypes();
                ticketTypesOfEvent = ticketTypesOfEvent.ticketTypes.sort((a, b) => (a.sortOrder > b.sortOrder) ? 1 : ((b.sortOrder > a.sortOrder) ? -1 : 0)); //sort

                var availabilities = ticketTypesOfEvent.map(async (tt) => {
                    let availability = await requestTicketTypeNumberOfAvailableTickets(tt.id, ""); //get availability
                    tt.availability = availability;
                });
                await Promise.all(availabilities);


                var infos = ticketTypesOfEvent.map(async (tt) => {
                    var ticketTypeInfos = await requestTicketTypeInfo(tt.id);
                    tt.info = ticketTypeInfos.ticketTypeInfos[0];
                });
                await Promise.all(infos);


                setTicketTypes(ticketTypesOfEvent);
            }

            loadEvent();

            //setLoading(false);
        }

    }, [orderId]); //everytime orderId is changed

    const requestResources = () => {
        axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data);
            })
            .catch((error) => console.log(error.response.data));
    };

    const startOrder = (posId) => {
        let order = {
            affiliateShopId: null,
            currency: "CHF",
            tenantId: 1,
            pointOfSaleId: posId,
            abbreviation: ""
        };

        axios
            .post(`${baseUrl}/ShopBasket/Order`, order)
            .then((res) => {
                sessionStorage.setItem("OrderId", res.data.id);

                setOrderId(res.data.id);
            })
            .catch((error) => console.error(error.response.data));
    };

    const requestEvent = async () => {
        var response = await axios.get(`${baseUrl}/Event/${eventId}`);
        return response.data;
    };

    const requestEventInfo = async () => {
        var response = await axios.get(`${baseUrl}/Event/${eventId}/Infos`);
        return response.data;
    };

    const requestTicketTypes = async () => {
        var response = await axios.get(`${baseUrl}/Event/${eventId}/TicketTypes`);
        return response.data;
    };

    const requestTicketTypeNumberOfAvailableTickets = async (ticketTypeId, code) => {

        if (ticketTypeId == null || code == null) return;

        let url = `${baseUrl}/TicketType/${ticketTypeId}/Availability?orderId=${orderId}`;

        if (code != "") {
            url = `${baseUrl}/TicketType/${ticketTypeId}/Availability?orderId=${orderId}&promotionCode=${promoCode}`;
        }

        var response = await axios.get(url);
        return response.data;
    };

    const requestTicketTypeInfo = async (ticketTypeId) => {
        var response = await axios.get(`${baseUrl}/TicketType/${ticketTypeId}/Infos`);
        return response.data;
    };

    const onTicketTypeChange = (e, ticketTypeId) => {
        if (selectedTicketTypes.length < 1) {
            selectedTicketTypes[0] = {
                ticketTypeId: ticketTypeId,
                quantity: e.target.value
            };
        } else {
            selectedTicketTypes.map((t) => {
                const testSelectedTicketTypes = selectedTicketTypes.filter((f) => f.ticketTypeId === ticketTypeId);

                if (testSelectedTicketTypes.length === 1) {
                    selectedTicketTypes.find((f) => f.ticketTypeId === ticketTypeId).quantity = e.target.value;
                } else {
                    selectedTicketTypes[selectedTicketTypes.length] = {
                        ticketTypeId: ticketTypeId,
                        quantity: e.target.value
                    };
                }
            });
        }

        let totalQuantity = 0;

        selectedTicketTypes.forEach((stt) => {
            totalQuantity = totalQuantity + stt.quantity * 1;
        });

        if (totalQuantity > 0) {
            setIsNotValid(false);
        } else {
            setIsNotValid(true);
        }
    };

    const mapTicketsTypes = () => {
        return (
            ticketTypes?.length > 0 &&
            ticketTypes.map(
                (tt, index) => {

                    if (tt.info && tt.availability && tt.availability.status === 0) {

                        return (
                            <div key={tt.id}>
                                <div className="row pt-3 pb-3 boxBorder ticket-center">
                                    <div className="col-md-9">
                                        <label>
                                            {tt.info.name}
                                        </label>
                                    </div>
                                    <div className="col-md-2">
                                        <label>{tt.currency} {tt.price} (exkl. MwSt.)</label>
                                    </div>
                                    <div className="col-md-1">
                                        <select className="form-select" onChange={(e) => onTicketTypeChange(e, tt.id)}>
                                            {Array.from(Array(tt.availability.availableTickets + 1),
                                                (e, i) => {
                                                    return <option key={i} value={i}>{i}</option>;
                                                })}
                                        </select>
                                    </div>
                                </div>
                            </div>);
                    }


                }
            )
        );
    };

    const applyPromotionCode = async () => {

        setLoading(true)

        var result = await axios.put(`${baseUrl}/ShopBasket/Order/${orderId}/PromotionCode/${promoCode}`)

        if (result.status !== 200) return;

        setPromoError("");

        setUnlockTicketTypes(result.data.unlockedTicketTypes);

        var t = result.data.unlockedTicketTypes.map(async (tt) => {

            let availability = await requestTicketTypeNumberOfAvailableTickets(tt, promoCode);

            var ticketType = ticketTypes.find(ticketType => ticketType.id === tt);

            if (ticketType) {
                ticketType.availability = availability;
            }
        });

        await Promise.all(t);

        setLoading(false)
    };

    const addTicketToBasket = () => {

        let addTicketTypes = [];

        addTicketTypes = selectedTicketTypes.filter((stt) => stt.quantity !== "0");

        addTicketTypes.map((tt) => {
            if (unlockTicketTypes.includes(tt.ticketTypeId)) {
                tt.promotionCode = promoCode;
            }
        });

        axios
            .post(`${baseUrl}/ShopBasket/Order/${orderId}/Tickets`, {
                ticketsToAdd: addTicketTypes,

            })
            .then(() => {
                navigate(`/${language}/shipping`);
            })
            .catch((error) => {
                console.error(error.response.data);
            });
    };


    const onSubmit = () => {
        sessionStorage.setItem("isCustomCheckboxChecked", customCheckboxText);
        addTicketToBasket();
    };

    let checkboxIsNotValid = false;
    let checkboxOptional = remarks?.CustomCheckboxOptional ?? false;

    if (remarks?.CustomCheckboxText) {
        if (!customCheckboxText && !checkboxOptional) {
            checkboxIsNotValid = true
        } else {
            checkboxIsNotValid = false;
        }
    }

    const onCustomCheckBoxChange = (customCheckboxText) => {
        setCustomCheckboxText(customCheckboxText);
    };

    return (
        <div>
            {/* loop the ticket types */}
            <>
                <div className="container p-0 px-2 wrapper">
                    <img id="banner" className="img-fluid w-100" src={eventInfo && eventInfo.bannerImagePath} alt="banner" />
                </div>

                {resources.translation && (
                    <div className="container pt-0 wrapper">
                        <div className="row mt-5">
                            <div className="col-md-12 text-center">
                                <h2 className="text-uppercase underline50">
                                    {eventInfo && eventInfo.name}
                                </h2>
                            </div>
                        </div>

                        <div className="row mt-3 mb-5">
                            <div className="col-md-12 mb-4 text-center description">
                                {eventInfo &&
                                    <span dangerouslySetInnerHTML={{ __html: eventInfo.longDescription }} className="description"></span>
                                }
                            </div>
                        </div>

                        {mapTicketsTypes()}

                        {/* promoCode */}
                        <div className="row pt-3 pb-3 boxBorder">
                            <div className="col-md-10">
                                <input
                                    id="inputCode"
                                    type="text"
                                    name="promotionCode"
                                    className="form-control border-0 mb-1 ps-0"
                                    placeholder="Promotion-Code"
                                    onChange={(e) => setPromoCode(e.currentTarget.value)}
                                    value={promoCode}
                                />
                                <span className="errorText">{promoError}</span>
                            </div>
                            <div className="col-md-2 text-end">
                                <button className="btn btn-lightblue form-control btnLightblue" onClick={applyPromotionCode} disabled={promoCode === ""}>
                                    {resources.translation.HomeRendeem}
                                </button>
                            </div>
                        </div>

                        {remarks?.CustomCheckboxText && (
                            <div className="float-end position-relative mt-4 pe-3 pb-4">
                                <div className="col-md-12 float-end">
                                    <label htmlFor="chkCustomCheckboxText" className="me-3 lblAgreement">{remarks?.CustomCheckboxText}</label>
                                    <input id="chkCustomCheckboxText" type="checkbox" className="checkboxAgreement form-check-input" checked={customCheckboxText} onChange={() => onCustomCheckBoxChange(!customCheckboxText)} />
                                </div>
                            </div>
                        )}

                        <div className="row pt-3 boxBorder">
                            <div className="offset-md-9 col-md-3 text-end">
                                <button className="btn form-control btnDarkblue" onClick={onSubmit} disabled={isNotValid || checkboxIsNotValid}>
                                    {resources.translation.HomeAddTobasket}
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                <div className="container wrapper">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <Footer language={language} />
                        </div>
                    </div>
                </div>
            </>
        </div>
    );
};

export default Home;